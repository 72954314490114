@import '~bootstrap/scss/bootstrap';

// General theme contents
@import 'partials/_modal';
@import 'partials/_tables';
@import 'partials/_variables';
@import 'partials/_general';
@import 'partials/_generic';
@import 'partials/mixins/_function';
@import 'partials/_ag-grid';
@import 'partials/_swal2';
@import 'partials/_calendar';

// important Element
@import 'partials/menu/_menu-lite';
@import 'partials/widget/_widget';

// // Theme Element
@import 'partials/theme-elements/_form';
@import 'partials/theme-elements/_radiobox-checkbox';
@import 'partials/theme-elements/_labels-badges';
@import 'partials/theme-elements/_data-tables';
@import 'partials/theme-elements/_authentication';
@import 'partials/theme-elements/_button';
@import 'partials/theme-elements/_alert';
@import 'partials/theme-elements/_breadcrumb-pagination';
@import 'partials/theme-elements/_progress';
@import 'partials/theme-elements/_tooltip';
@import 'partials/theme-elements/_popover';
@import 'partials/theme-elements/_nav';
@import 'partials/_modal';
@import 'partials/_errors';

// // Other
@import 'partials/other/_chat';
@import 'partials/other/_prism';
@import 'partials/other/_switches';

@import 'partials/other/_chart';
@import 'partials/other/_icon-lauouts';

@import '~react-toastify/dist/ReactToastify.css';
@import 'react-big-calendar/lib/sass/styles';
