@keyframes selected {
    0% {
        background-color: #dbdbdb;
    }
    20% {
        background-color: #dbdbdb;
    }
    50% {
        background-color: #f5f5f5;
    }
    75% {
        background-color: #dbdbdb;
    }
    100% {
        background-color: #dbdbdb;
    }
}

.button-right {
    place-self: center;
    white-space: pre;
}

.ag-theme-alpine {
    border-top: solid !important;
    border-top-color: #d6d8db !important;
    border-top-width: 0.5px !important;
    .ag-header {
        border-bottom-color: #d6d8db !important;
        border-bottom: solid 0.5px;
    }
}
