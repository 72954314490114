.error-heading {
    margin-top: 115px;
    .headline {
        font-size: 285px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-top: -130px;
        // font-family: $font-work-sans, $font-serif;
    }
}

.dog-error {
    margin-bottom: -60px;
    margin-right: 300px;
}

.error-wrapper {
    padding: 20px 0;
    .btn {
        margin-top: 15px;
    }
    .img-100 {
        width: 100px !important;
    }
    .error-heading {
        margin-top: 20px;
        .cloud-second {
            margin-top: -60px;
        }
        .headline {
            font-size: 150px;
        }
    }
    .maintenance-heading {
        .cloud-second {
            display: none;
        }
        .headline {
            font-size: 25px;
            margin-top: -10%;
            letter-spacing: 2px;
        }
    }
}

// SECHEDULEEEE

.css-1rit21i-MuiInputBase-root-MuiOutlinedInput-root .Switcher-input {
    background: none !important;
    font-size: 12px !important;
    height: auto !important;
    border-radius: 3rem !important;
}

.css-1rit21i-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 2rem !important;
}

// SECHEDULEEEE
