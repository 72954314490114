.ag-watermark {
  visibility: hidden !important;
}

.ag-watermark-text {
  visibility: hidden !important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  font-size: 14px !important;
  // line-height: 3;
}

.ag-theme-acmecorp {
  --ag-odd-row-background-color: #ffffff;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  font-size: 14px;
  line-height: 3;
  color: #666666;
}
.ag-theme-alpine .ag-root-wrapper {
  // border-radius: 2rem !important;
  border: solid 1px !important;
  border-color: #fbfbfb !important;
  border-color: #f9f9f9 !important;
  border-color: #ffffff !important;
}

.ag-theme-material {
  --ag-material-primary-color: #2f9f8d !important;
  --ag-material-accent-color: #2f9f8d !important;
}

.state-aproved {
  color: #31d036 !important;
  font-weight: 700;
}

.state-rejected {
  color: #f44236 !important;
  font-weight: 700;
}

.state-pending {
  color: #ffc400 !important;
  font-weight: 700;
}

.ag-header-viewport {
  background: white !important;
}

.ag-header-row {
  // color: #8c8a88 !important;
  color: $primary-color !important;
}

.ag-theme-alpine .ag-paging-panel {
  color: #858585 !important;
}

.bg-pending {
  background: #ffe76059 !important;
}

.bg-rejected {
  background: #ff6a6059 !important;
}

.bg-aproved {
  background: #60ffb259 !important;
}

.bg-selected {
  background-color: rgb(184, 184, 184);
  // right: 16px;
  // bottom: 37px;
  animation-name: selected;
  animation-duration: 1.8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

// .ag-cell-first-right-pinned {
//   left: 16px !important;
// }

.ag-pinned-right-cols-container {
  margin-right: 0px !important;
}
// ag-cell-not-inline-editing
// ag-cell-normal-height
// ag-cell-first-right-pinned
// ag-cell-range-right
// ag-cell-value
