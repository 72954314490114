.rbc-row-content {
  display: none;
}

.rbc-label {
  font-size: 15px;
  color: #8d9196;
}

.rbc-today {
  background: #15bba021 !important;
}

// .rbc-time-view {
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   width: 100%;
//   border: 1px solid #e9edf3 !important;
//   border-radius: 5px;
//   min-height: 0;
// }

// .rbc-time-header.rbc-overflowing {
//   /* border: 1px solid #e9edf3 !important; */
//   border: none !important;
//   border-color: #e9edf3 !important;
//   /* margin-right: 10px !important; */
// }

// .rbc-time-content > * + * > * {
//   border-color: #e9edf3 !important;
// }

// .rbc-allday-cell {
//   display: none;
// }

// .rbc-time-header-content {
//   border-left: 1px solid #e9edf3 !important;
// }

// .rbc-header {
//   border-left: 1px solid #e9edf3 !important;
//   border-bottom: 1px solid #e9edf3 !important;
//   /* min-height: 35px !important;
//     padding: 6px 3px !important; */
// }

// .rbc-day-slot .rbc-event,
// .rbc-day-slot .rbc-background-event {
//   border: 1px solid #e2e8f0 !important;
//   padding: 5px 8px !important;
//   background-color: #a6a6a6;
//   border-radius: 6px;
// }

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid #e2e8f0 !important;
  padding: 5px 8px !important;
  background-color: $primary-color !important;
  border-radius: 6px;
}

.no-overflow .rbc-time-content {
  overflow-y: hidden !important;
}
// .rbc-time-content {
//   border-top: 0px solid #a6a6a6 !important;
// }
// /* .rbc-row-content {
//     border-color: #e9edf3 !important;
//   } */

// .rbc-current-time-indicator {
//   position: absolute;
//   z-index: 3;
//   left: 0;
//   right: 0;
//   height: 3px !important;
//   background-color: $primary-color !important;
//   pointer-events: none;
// }
